.realization{
    margin: 8% 2% 2% 2%;
}
.title{
    text-align: center;
    margin-bottom: 40px;
}
.image-container {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-bottom: 20px; 
}
.selected-image{
    display:flex;
    justify-content:center;
    max-width: 1000px;
    width:75%;
    max-height:550px;
    height: 40%;
    margin: 30px auto;
}
.button-prev-selected-image{
    margin-left: 10%;
    font-size: 30px;
    cursor: pointer;
}
.button-next-selected-image{
    margin-right: 10%;
    font-size: 30px;
    cursor: pointer;
}
.private-fonction{
    display: flex;
    justify-content: space-between;
}
.division{
    margin-top: 20px;
}
.in-division {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.list-min-picture{
    display: flex;
    flex-wrap: nowrap;   
    gap: 20px;
}
.min-image{
    width:200px;
    height: 200px;
    cursor: pointer; 
    object-fit: cover;
}
.button-prev-image{
    margin: 2%;
    cursor: pointer;
    margin-top: 7%;
}
.button-next-image{
    margin: 2%;
    cursor: pointer;
    margin-top: 7%;
}
.btn-add{
    border: none;
    border-radius: 20px;
    background: linear-gradient(#38B6FF, white);
    color: black;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.btn-add:hover {
    background: linear-gradient(white, #38B6FF)
}
.btn-form{
    color: #38B6FF;
    background: white;
    border: none;
    font-size: 20px;
}
.btn-form:hover{
    color: #38B6FF;
    background-color: white;
}
@media (max-width: 430px) {
    .realization{
        margin: 14% 1% 1% 1%;
        max-width: 26rem;
        width:100%;
    }
    .selected-image{
        max-width: 75%;
        height: auto;
        margin: 20px auto;
    }
    .private-fonction{
        width: 100%;
        flex-direction: row;
        align-items: center;
    }
    .list-min-picture{
        top:4%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
    }
    .min-image{
        width: 100%;
        height: 90px;
        margin-bottom: 10px;
    }
    .button-prev-image,
    .button-next-image {
        margin-top: 4%;
    }
    .button-prev-selected-image,
    .button-next-selected-image {
        margin-top: 8%;
    }
    .btn-add{
        font-size: 16px;
        padding: 8px 16px;
    }
    .btn-form {
        font-size: 18px;
    }
    .modal-form{
        border: 2px;
        border-color: #38B6FF;
        justify-content: center;
        width: 90%;
    }
}
@media (max-width: 768px) {
    .realization{
        max-width: 48rem;
        width:100%;
        justify-content: center;
    }
    .selected-image{
        width: 95%;
    }
    .list-min-picture{
        width:100%;
    }
    .min-image{
        width: 100%;
    }
}
