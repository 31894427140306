*{
  box-sizing:border-box
}
html, body{
  width: 100%;
  padding: 0;
  margin: 0;
}
.register{
  margin-top: 2%;
  display: flex;
  justify-content: center;
  text-align: center;
}