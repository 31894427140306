.footer{    
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.part1{
    margin-top: 1%;
    margin-left: 2%;
    width:30%
}
.part2{
    margin-top: 1%;
    margin-right: 4%;
}
.part3{
    margin-top: 1%;
    margin-right: 7%;
}
.part3 span{
    margin-left: 10px;
}
@media (max-width: 430px) {
    .footer{
        max-width: 26rem;
        width:100%;
    }
    .part1{
        margin-top: 2%;

    }
    .part2{
        margin-top: 2%;
        margin-left: 0.7%;
    }
    .part3{
        margin-top: 2%;
        margin-left: 0.7%;
    }
    .part1 h3{
        font-size:15px;
    }
    .part1 p{
        font-size: 11px;
    }
    .part2 h3{
       font-size: 15px;
    }
    .part2 a{
        font-size: 11px;
    }
    .part3 h3{
        font-size: 15px;
    }
    .part3 span{
        font-size: 11px;
    }
}
@media (max-width: 768px) {
    .footer{
        max-width: 48rem;
        width: 100%;
    }
}
