.about{
    margin: 8% 2% 2% 2%;
}
.title{
    text-align: center;
    color: #269adc;
}
.in-about{
    
    width:100%;
}


@media (max-width: 430px) {
    .about{
      margin: 14% 1% 1% 1%;
      max-width: 26rem;
      width:95%;
    }
    .in-about{
        width: 95%;
    }

    .in-about h4{
        font-size: 16px;
        
    }
    .in-about p{
        font-size: 11.5px;
    }
    .in-about h6{
        font-size: 13px;
    }
    
}
@media (max-width: 768px) {
    .about{
        max-width: 48rem;
        width:95%;
    }
    
}
