body {
    overflow-x: hidden; /* Empêche le débordement horizontal du contenu */
    overflow-y: visible;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}
a{
    text-decoration: none;
    color: black;
    line-height: 1;
    cursor: pointer;
}
a:hover {
    color:#38B6FF;
}
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 16%;
    z-index: 1000;
    background-color:white; 
    overflow: hidden;
    list-style: none;
}
.container {
    margin-bottom: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo {
    height: 120px; /* Taille du logo */
    width: 120px;
    margin-top: -15px;
    margin-left: 40px;
}
.nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.nav-links li {
    padding: 0 15px;
}
.nav-links li a {
    color: black; /* Couleur du texte du lien */
    text-decoration: none;
}
.admin{
    margin-left: 80%;
    margin-bottom: -40%;
    color:#269adc;
    text-shadow: 1px 1px  1px ;
    font-size: 22px;
}
.logout{
    margin-top: 30% !important;
    cursor: pointer !important;
}
.logout:hover {
    color:#38B6FF;
}
@media (max-width: 768px) {
  .navbar{
    max-width: 48rem;
    width:100%;
    position:fixed;
    top: 0;
    z-index: 1000;
  }

  .container{
    width: 100%;
    display: block;
    margin-right: 20%;
  }
  .logo {
    height: 90px;
    width: 90px;
  }
  .nav-links {
    display:contents;
     
  }
  .nav-links li {
    margin-bottom: 10px; 
  }
}

@media (max-width: 540px) {
  .navbar{
    max-width: 33rem;
    width:100%;
    height:auto;
    position:fixed;
    top: 0;
    z-index: 1000;
  }

  .container{
    width: 100%;
    display: block;
    margin-top: 2%;
  }
  .logo {
    height: 90px;
    width: 90px;
  }
  .nav-links {
    display:contents;  

  }
  .nav-links li {
    margin-bottom: 10px; 
  }
}
/* Media Query pour les écrans de très petite taille (max-width: 430px) */
@media (max-width: 430px) {
    .navbar {
        max-width: 26rem;
        width:100%;
        top:0%;
        height:auto;
        position:fixed;
        z-index: 1000;
      }
    .container {
        margin-left: -5%;
        margin-top: 4%;
      }
      .logo {
        height: 70px;
        width: 70px;
        bottom: 0%;
        
      }
      .nav-links {
        display: none; 
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: fixed;
        top: 13%; 
        left: 0;
        background-color: white;
        padding: 10px 0;
        overflow: hidden;
        height:0;
        transition: height 0.3s ease;
      }
      .nav-links.active.expanded{
        height:auto;
        overflow: visible;
      }
      .nav-links.active {
        display: flex;
      }
      .nav-toggle {
        display: block;
        color: black;
        font-size: 1.5em;
        cursor: pointer;
        float: right;
      }
      .nav-toggle:hover{
        color:#38B6FF; 
      }
      .nav-links li {
        padding: 10px 0; 
      }
      .login{
        position: absolute;
        top: 50%; 
        transform: translateY(-50%); 
        right: 50px; 
      }
      .admin{
        position: absolute;
        top: 35px;
        right:80px;
        transform: translateY(-50%); 
      }

}
