.contactus{
    margin: 8% 2% 2% 2%;
}
.container-1{
    width: 40%;
}
.iframe{
    max-width: 900px;
    width: 100%;
    height: auto;
    border: 0; 
}
@media (max-width: 430px) {
    .contactus{
        margin: 14% 1% 1% 1%;
        max-width: 26rem;
        width:100%;    }
    .container-1{
        width: 100%;
    }
    .iframe {
        max-width: 100%; /* Modifier la largeur maximale à 100% pour s'adapter à la largeur de l'écran */
        width: 90%;
        height: auto;
        border: 0; 
    }
}
@media (max-width: 768px){
    .contactus{
        max-width: 48rem;
        width: 100%;
    }
    .iframe{
        width:97%;
    }
}
