.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 73vh;
  }
  .login-card {
    width: 300px; /* Ajustez la largeur selon vos besoins */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
.btn-submit{
    margin-top: 4%;
    color: #38B6FF;
    background: white;
    border: none;
    font-size: 20px;
}
