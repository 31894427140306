.devis{
    margin: 8% 2% 2% 2%;
}
.btn-add{
    margin-top: 2% !important;
    border: none;
    border-radius: 20px;
    background: linear-gradient(#38B6FF, white);
    color: black;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.btn-add:hover {
    color: black;
    background: linear-gradient(white, #38B6FF)
}
.btn-modal{
    color: #38B6FF;
    background: white;
    border: none; 
    font-size: 20px;
}
.btn-modal:hover{
    color: #38B6FF;
    background-color: white;
}
@media (max-width: 430px) {
    .devis{
        margin: 14% 1% 1% 1%;
        max-width: 26rem;
        width:100%;
    }
    .modal-form{
        border: 2px;
        border-color: #38B6FF;
        justify-content: center;
        width: 90%;
    }
}
@media (max-width: 768px) {
    .devis{
        max-width: 48rem;
        width:100%;
    }
}
