.feedback{
    margin: 8% 2% 2% 2%;
}
.modal-add{
    display: flex;
    flex-direction: column;
}
.list-feedbacks{
    margin-bottom: 3%;
    display: flex;
    flex-direction: row;    
}
.card{
    width: 25rem;
    height: 20rem;
    margin: 10px
}
.card-header{
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-bottom-color:#38B6FF;
    
}
.card-body-text{
    font-size: 13px;
}
.card-footer{
    background-color: white;
    border-top-color: #38B6FF;
}
.modal-form{
    border: 2px;
    border-color: #38B6FF;
}
.btn-add{
   
    border: none;
    border-radius: 20px;
    background: linear-gradient(#38B6FF, white);
    color: black;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.btn-add:hover {
    background: linear-gradient(white, #38B6FF)
}
.btn-modal{
    color: #38B6FF;
    background: white;
    border: none; 
    font-size: 20px;
}
.btn-modal:hover{
    color: #38B6FF;
    background-color: white;
}
@media (max-width: 430px) {
    .feedback {
        margin: 14% 1% 1% 1%;
        max-width: 26rem;
        width:100%;
        justify-content: center;
    }
    .list-feedbacks {
        display: flex;
        justify-content: center;
    }
    .card {
        width:calc(70% - 30px) !important;
    }
    .btn-add {
        font-size: 16px;
        padding: 8px 16px;
    }
    .btn-modal {
        font-size: 18px;
    }
    .card-body-text{
        font-size: 11px;
    }
    .modal-form{
        border: 2px;
        border-color: #38B6FF;
        justify-content: center;
        width: 90%;
    }
}
@media (max-width: 768px) {
    .feedback{
        max-width: 48rem;
        width: 100%;
    }
    .list-feedbacks{
        width: 90%;
    }
    .card{
        width: calc(100% / 3 - 10px);
    }
    .modal-form{
        border: 2px;
        border-color: #38B6FF;
        justify-content: center;
        width: 90%;
    }
}
