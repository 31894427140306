body {
  overflow-x: hidden;
}
.home {
   
    width: 100% !important ; 
    margin-top: 90px;
  }
  .container-item {
   
    width: 100% !important ;
    height: auto;
  }
  
  .container-item-image {
    width: 100% !important ;
    height: auto;
  }
  @media (max-width: 768px) {

    .home-page{
      max-width: 48rem;
      width: 100%;
    }
    .container-item-image{
      margin-top:12%;
      max-width: 48rem;
      width: 100%;
    }
  }
  @media (max-width: 430px) {
    .home-page{
      max-width: 26rem;
      width:100%;
    }
    .home{
      width: 100% ;
    }
    .container-item{
      width: 100% ;
    }
    .container-item-image{
      max-width:26rem;
      width: 90%;
    }
  }
